@import "assets/stylesheet/variables.scss";

#epub-render {
  .epub-container {
    width: 100% !important;
    height: 100% !important;
  }

  //   @media (max-width:650px) {
  div[style*="inset"] {
    inset: unset !important;
    width: 100% !important;
    height: 100% !important;
    //   }
  }

  .epub-view {
    width: 100% !important;
    // height: 100% !important;
    // overflow: auto !important;

    .epub-viewer-next,
    .epub-viewer-previous {
      display: none !important;
    }
  }
  iframe {
    width: 100% !important;
    height: 100% !important;
    overflow: scroll !important;
  }
  body {
    width: 100% !important;
    // height: 100% !important;
  }
}

.epub_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 8px;
  padding: 0 10px;

  .right_arrow_btn{
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
  }
  .left_arrow_btn{
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
  }
}

.epub_buttons_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  margin: 0.75rem 0;
  position: relative;
  z-index:1;

  .last_page_exit_btn {
      position: absolute;
      right: 60px;
      top: 40%;
      z-index: 3;
      transform: translateY(-42%);
      height: 50px;
      font-size: 1.2rem;
      font-weight: 500;
    }
}

.epub_button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  min-width: 6rem;

  .button_container_waves{
    position: relative;
    z-index: 1;
  }

  .epub_button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $magenta-color;
    border: none;
    outline: none;
    border-radius: 50%;
    padding: 0;
    z-index: 1;

    &:disabled{
      opacity: 0.75;
    }

    img {
      height: 22px;
    }

    svg{
      color: white;
    }
  }

  .main {
    width: 85px;
    height: 85px;
    border: 1px solid white;
    backdrop-filter: blur(20px);
    box-shadow: -0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset;

    img{
      height:50%;
    }
  }

  .stop_recording_btn {
    background-color: rgba(217, 217, 217, 1);

    img {
      height: auto;
    }
  }

  .epub_read_aloud_icon{
    transform: translate(-1px,-3px);

    @media (max-width:650px) {
      transform: translate(0,-2px);
    }
  }
  .select_voice_btn{
    position: relative;
    img{
      position: absolute;
      top: 0;
      bottom: 10px;
      width: 40px;
      height: 40px;
      left:2px;
    }
  }

  .epub_btn_text {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: $theme-text-color;
  }
}

.playback-slider-container {
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: $theme-border-radius;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  .playback-slider{
    height: 150px;

    .ant-slider-track{
      background-color: $magenta-color;
      border-radius: $theme-border-radius;
    }

    .ant-slider-handle{
      border-color: $magenta-color;

      &:focus{
        border-color: $magenta-color;
        box-shadow: 0 0 0 5px #d2adf0;
      }
    }
    &:hover{
      .ant-slider-track{
        background-color: $magenta-color;
      }

      .ant-slider-handle{
        border-color: $magenta-color !important;
      }
    }
  }
}

.select_voice_option{
  p{
    margin: 0 0 0.5rem 0;
  }
}

.select_voice_option.active{
  background-color: #d2adf0;
}

@media (max-width:1040px) {
  .epub_buttons_container{
    gap:1rem;

    .last_page_exit_btn {
      right: 15px;
      font-size: 0.75rem;
      height: 45px;
    }
  }

  .epub_button_container{
    min-width: 5rem;
    .epub_button{
      width: 45px;
      height: 45px;

      img{
        height: 20px;
      }
    }

    .main{
      width: 60px;
      height: 60px;

      img{
        height: 25px;
      }
    }

    .select_voice_btn{
      img{
        width: 35px;
        height: 35px;
        left: 2px;
      }
    }

    .epub_btn_text{
      font-size: 10px;
      width: 100%;
      text-align: center;
    }
  }
}

@media (max-width:650px) {
  .epub_buttons_container{
    gap:0.75rem;
    .last_page_exit_btn {
     display: none;
    }
  }

  .epub_button_container{
    min-width: auto;
    .epub_button{
      width: 35px;
      height: 35px;

      img{
        height: 15px;
      }
    }

    .main{
      width: 50px;
      height: 50px;

      img{
        height: 23px;
      }
    }

    .select_voice_btn{
      img{
        width: 25px;
        height: 25px;
        left: 2px;
      }
    }

    .epub_btn_text{
      font-size: 10px;
      display: none;
    }
  }
}


// .stop_recording_btn::before,
// .stop_recording_btn::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 100%;
//   height: 100%;
//   background: rgba(242, 74, 74, 0.5);
//   border-radius: 50%;
//   transform: translate(-50%, -50%) scale(1);
//   animation: pulse 2.5s infinite ease-out;
// }

// /* Second wave delay */
// .stop_recording_btn::after {
//   animation-delay: 1s;
// }

// /* Keyframes for wave animation */
// @keyframes pulse {
//   0% {
//     transform: translate(-50%, -50%) scale(1);
//     opacity: 0.8;
//   }
//   100% {
//     transform: translate(-50%, -50%) scale(1.75);
//     opacity: 0;
//   }
// }

.stop_recording_btn {
  position: relative; /* Needed for absolute positioning of waves */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.waves {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  animation: pulse 2.5s infinite ease-out;
  z-index: 0; /* Keep waves below */
}

// /* Second wave with delay */
.waves::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.3);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  animation: pulse 2.5s infinite ease-out 1s;
}

/* Keyframes */
@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
